import {
  IssuesCloseOutlined,
  RetweetOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  VerticalAlignTopOutlined,
  PlusCircleOutlined,
  VerticalAlignBottomOutlined,
  WarningOutlined,
  StopOutlined,
  CloseCircleOutlined,
  UnlockOutlined,
  DeleteOutlined,
  CameraOutlined,
} from "@ant-design/icons"
import { notification } from "antd"
import React from "react"

export const openNotificationApprove = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <CheckOutlined style={{ color: color }} />,
  })
}

// //Hiện nofi chấm than trong vòng tròn
export const openNotificationBlock = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <IssuesCloseOutlined style={{ color: color }} />,
  })
}

export const openNotificationUnblock = (message, description, color) => {
  notification.open({
    message: message,
    description: description,

    icon: <UnlockOutlined style={{ color: color }} />,
  })
}

// /Hiện noti dấu cộng trong vòng tròn
export const openNotificationSuccess = (message, description, color) => {
  notification.open({
    message: message,
    description: description,

    icon: <PlusCircleOutlined style={{ color: color }} />,
  })
}

// /Hiện noti nút chấm than tam giác
export const openNotificationWarning = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <WarningOutlined style={{ color: color }} />,
  })
}

// /Hiện noti dấu cấm
export const openNotificationFail = (message, description, color) => {
  notification.open({
    message: message,
    description: description,

    icon: <StopOutlined style={{ color: color }} />,
  })
}

// /Hiện noti dấu cấm
export const openNotificationFailAddFavoriteList = (
  message,
  description,
  color
) => {
  notification.open({
    message: message,
    description: description,
    icon: <StopOutlined style={{ color: color }} />,
    style: {
      width: 410,
    },
  })
}

// /Hiện noti hai dòng qua lại
export const openNotificationChangeSuccess = (message, description, color) => {
  notification.open({
    message: message,
    description: description,

    icon: <RetweetOutlined style={{ color: color }} />,
  })
}

export const openNotificationTakePhotoSuccess = (
  message,
  description,
  color
) => {
  notification.open({
    message: message,
    description: description,

    icon: <CameraOutlined style={{ color: color }} />,
  })
}
// /Hiện noti  tải lên
export const openNotificationUp = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <VerticalAlignTopOutlined style={{ color: color }} />,
  })
}

export const openNotificationDeleteSuccess = (message, description, color) => {
  notification.open({
    message: message,
    description: description,

    icon: <DeleteOutlined style={{ color: color }} />,
  })
}

// /Hiện noti tải xuống
export const openNotificationDow = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <VerticalAlignBottomOutlined style={{ color: color }} />,
  })
}

// /Hiện noti tích v trong vòng tròn
export const openNotificationSuccessNG = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <CheckCircleOutlined style={{ color: color }} />,
    style: { width: 500 },
  })
}

export const openNotificationSuccessReject = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: <CloseCircleOutlined style={{ color: color }} />,
  })
}
export const openNotificationNoIcon = (message, description, color) => {
  notification.open({
    message: message,
    description: description,
    icon: "",
  })
}
