import { Row, Typography, Tabs, Button, Breadcrumb, Space, Spin } from "antd"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Preview from "../../../customComponents/previews"
import ThankYou from "../../../pages/company-info/register/thank-you/ThankYou"
import { getProductId, updateVariant } from "../../../services/apiProduct"
import BasicInfoConfirm from "./BasicInfoConfirm"
import CommitmentConfirm from "./CommitmentConfirm"
import DetailProductConfirm from "./DetailProductConfirm"
import PriceConfirm from "./PriceConfirm"
import { Content } from "antd/lib/layout/layout"
import {
  openNotificationFail,
  openNotificationSuccess,
} from "../../../components/notification"

const { TabPane } = Tabs

const steps = [
  {
    title: "入力",
    content: "First-content",
  },
  {
    title: "確認",
    content: "Second-content",
  },
  {
    title: "完了",
    content: "Last-content",
  },
]

const SuperListedProductDetail = ({ product_id }) => {
  const [activeKey, setActiveKey] = useState("1")

  const [dataInputBasicInfo, setDataInputBasicInfo] = useState("")
  const [dataInputPrice, setDataInputPrice] = useState("")
  const [dataInputDetail, setDataInputDetail] = useState("")
  const [dataInputCommitment, setDataInputCommitment] = useState("")
  const [dataProduct, setDataProduct] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isloadingUpdate, setLoadingIsLoadingUpdate] = useState(false)

  useEffect(() => {
    if (product_id) {
      ;(async () => {
        setLoading(true)
        const { data } = await getProductId(product_id)

        if (data) {
          setDataProduct(data?.product)
          const dataVariantMin =
            data?.product.variants.find((item) => item.weight === 0) ?? null
          const dataVariantMedium =
            data?.product.variants.find((item) => item.weight === 1) ?? null
          const dataVariantMax =
            data?.product.variants.find((item) => item.weight === 2) ?? null
          let prices = {}
          if (data?.product.is_constant) {
            prices = {
              Check: data?.product?.variants?.some((item) => item.weight === 0)
                ? []
                : ["販売不可"],
              Checkbox: data?.product?.variants?.some(
                (item) => item.weight === 1
              )
                ? []
                : ["販売不可"],
              Checkbox2: data?.product?.variants?.some(
                (item) => item.weight === 2
              )
                ? []
                : ["販売不可"],
              Minimum: dataVariantMin?.minnum_ship,
              StockQuantity: dataVariantMin?.inventory_quantity,
              Wholesale: dataVariantMin?.price,
              BulkShipment: dataVariantMin?.price_amount,
              MinimumNumberOfCase:
                dataVariantMedium?.metadata?.MinimumNumberOfCase,
              QuantityCase: dataVariantMedium?.metadata?.QuantityCase,
              StockQuantity2: dataVariantMedium?.inventory_quantity,
              WholesaleUnitPrice:
                dataVariantMedium?.metadata?.WholesaleUnitPrice,
              ShippingWholesalePrice:
                dataVariantMedium?.metadata?.ShippingWholesalePrice,
              Commissions2: dataVariantMedium?.metadata?.Commissions2,
              Minimum3: dataVariantMax?.metadata?.Minimum3,
              StockQuantity3: dataVariantMax?.inventory_quantity,
              WholesalePrice3: dataVariantMax?.metadata?.WholesalePrice3,
              LotShippingPrice3: dataVariantMax?.metadata?.LotShippingPrice3,
              Commission3: dataVariantMax?.metadata?.Commission3,
            }
          } else {
            prices = {
              PriceMin: {
                Check: data?.product?.variants?.some(
                  (item) => item.weight === 0
                )
                  ? []
                  : ["販売不可"],
                Minimum: dataVariantMin?.minnum_ship,
                Quantity: dataVariantMin?.inventory_quantity,
                WholesalePrice: dataVariantMin?.price,
                ContentPerOne: {
                  First: dataVariantMin?.metadata?.ContentPerOneFirst,
                  Last: dataVariantMin?.metadata?.ContentPerOneLast,
                },
              },
              PriceMid: {
                Check: data?.product?.variants?.some(
                  (item) => item.weight === 1
                )
                  ? []
                  : ["販売不可"],
                Minimum: dataVariantMedium?.minnum_ship,
                Quantity: dataVariantMedium?.inventory_quantity,
                WholesalePrice: dataVariantMedium?.price,
                ContentPerOne: {
                  First: dataVariantMedium?.metadata?.ContentPerOneFirst,
                  Last: dataVariantMedium?.metadata?.ContentPerOneLast,
                },
              },
              PriceLarge: {
                Check: data?.product?.variants?.some(
                  (item) => item.weight === 2
                )
                  ? []
                  : ["販売不可"],
                Minimum: dataVariantMax?.minnum_ship,
                Quantity: dataVariantMax?.inventory_quantity,
                WholesalePrice: dataVariantMax?.price,
                ContentPerOne: {
                  First: dataVariantMax?.metadata?.ContentPerOneFirst,
                  Last: dataVariantMax?.metadata?.ContentPerOneLast,
                },
              },
            }
          }
          setDataInputBasicInfo({
            ...data?.product,
            collection_id_min: data?.product.collection_id,
            images: data?.product?.images
              .filter((item) => item.metadata.type === "image")
              .map((item, index) => {
                return {
                  ...item,
                  metadata: {
                    ...item.metadata,
                    index: item.metadata.index ?? index,
                  },
                }
              })
              .sort((a, b) => a.metadata.index - b.metadata.index),
          })
          setDataInputPrice({
            ...data?.product,
            PriceStandard:
              data?.product?.is_constant === true ? "定貫" : "不定貫",
            UnitSetting: {
              Roses: dataVariantMin
                ? dataVariantMin?.prices[dataVariantMin?.prices?.length - 1]
                    ?.unit
                : dataVariantMedium
                ? dataVariantMedium?.prices[
                    dataVariantMedium?.prices?.length - 1
                  ]?.unit
                : dataVariantMax?.prices[dataVariantMax?.prices?.length - 1]
                    ?.unit,
              Case: data?.product?.case_unit,
            },
            SettingLoose: {
              Text: data?.product?.internal_capacity,
              Select: dataVariantMin
                ? dataVariantMin?.prices[dataVariantMin?.prices?.length - 1]
                    ?.another_unit
                : dataVariantMedium
                ? dataVariantMedium?.prices[
                    dataVariantMedium?.prices?.length - 1
                  ]?.another_unit
                : dataVariantMax?.prices[dataVariantMax?.prices?.length - 1]
                    ?.another_unit,
            },
            ShippingMin: {
              Checkbox: dataVariantMin?.is_free_ship,
              Policy: dataVariantMin?.policy_return,
              Cancellation: dataVariantMin?.cancel_date,
              Return: dataVariantMin?.return_date,
            },
            ShippingMed: {
              Checkbox: dataVariantMedium?.is_free_ship,
              Policy: dataVariantMedium?.policy_return,
              Cancellation: dataVariantMedium?.cancel_date,
              Return: dataVariantMedium?.return_date,
            },
            ShippingLarge: {
              Checkbox: dataVariantMax?.is_free_ship,
              Policy: dataVariantMax?.policy_return,
              Cancellation: dataVariantMax?.cancel_date,
              Return: dataVariantMax?.return_date,
            },
            ShippingMinSelects: dataVariantMin?.product_shipping,
            ShippingMedSelects: dataVariantMedium?.product_shipping,
            ShippingLargeSelects: dataVariantMax?.product_shipping,
            JANcode: dataVariantMin?.hs_code,
            JANcode2: dataVariantMedium?.hs_code,
            JANcode3: dataVariantMax?.hs_code,
            conditional_free_shippingMin_count:
              dataVariantMin?.condition_freeship,
            min_number_postage_additions_count:
              dataVariantMin?.condition_feeship,
            conditional_free_shippingMed_count:
              dataVariantMedium?.condition_freeship,
            med_number_postage_additions_count:
              dataVariantMedium?.condition_feeship,
            conditional_free_shippingLarge_count:
              dataVariantMax?.condition_freeship,
            large_number_postage_additions_count:
              dataVariantMax?.condition_feeship,
            ...prices,
          })
          setDataInputDetail({
            ...data?.product,
            date_setting: data?.product?.date_setting?.toString(),
            tags: data?.product.tags.reduce((accumulator, currentValue) => {
              if (currentValue?.metadata?.type === "allergy") {
                accumulator.push(currentValue?.value)
              }
              return accumulator
            }, []),
            images: data?.product?.images
              .filter(
                (item) =>
                  item.metadata.type === "accuracyImg1" ||
                  item.metadata.type === "accuracyImg2"
              )
              .map((item, index) => {
                return {
                  ...item,
                  metadata: {
                    ...item.metadata,
                    index: item.metadata.index ?? index,
                  },
                }
              })
              .sort((a, b) => a.metadata.index - b.metadata.index),
          })
          setDataInputCommitment({
            origin: data?.product.tags.reduce((accumulator, currentValue) => {
              if (currentValue?.metadata?.type === "origin") {
                accumulator.push(currentValue?.value)
              }
              return accumulator
            }, []),
            saleTime: data?.product.tags.reduce((accumulator, currentValue) => {
              if (currentValue?.metadata.type === "saleTime") {
                accumulator.push(currentValue?.value)
              }
              return accumulator
            }, []),
            businessFormat: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "businessFormat") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
            usageMethod: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "usageMethod") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
            proudPoint: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "proudPoint") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
            customerBase: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "customerBase") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
            popularity: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "popularity") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
            temperatureZone: data?.product.tags.reduce(
              (accumulator, currentValue) => {
                if (currentValue?.metadata.type === "temperatureZone") {
                  accumulator.push(currentValue?.value)
                }
                return accumulator
              },
              []
            ),
          })
          setLoading(false)
        }
      })()
    }
  }, [product_id])

  const onKeyChange = (key) => {
    setActiveKey(key)
  }

  const updateProduct = async () => {
    setLoadingIsLoadingUpdate(true)
    const arrayVariantId = []
    dataProduct?.variants?.forEach((element) => {
      arrayVariantId.push(element.id)
    })

    const { response } = await updateVariant({
      id: arrayVariantId,
      is_displayed: false,
    })
    if (response.status == 200) {
      setLoadingIsLoadingUpdate(false)

      openNotificationSuccess("正常に更新されました。", "", "green")
      navigate(-1)
    } else {
      setLoadingIsLoadingUpdate(false)
      openNotificationFail("更新に失敗しました。", "", "red")
    }
  }

  return (
    <div className="productTab">
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-listed-product-management/?activePage=1&pageCurrent=1&pageSize=10">
                掲載商品管理
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="text-[#888]">{dataInputBasicInfo?.title}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            {dataInputBasicInfo?.title}
          </Typography.Title>
        </div>

        <Spin spinning={loading}>
          <Tabs
            activeKey={activeKey}
            onChange={onKeyChange}
            // defaultActiveKey="1"
            //    onChange={onChange}
            size="large"
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              background: "#fff",
            }}
            tabBarStyle={{ background: "#fafafa" }}
          >
            <TabPane tab="商品基本情報登録" key="1" disabled={false}>
              <BasicInfoConfirm dataInputBasicInfo={dataInputBasicInfo} />
            </TabPane>

            <TabPane
              tab="商品規格詳細設定"
              key="3"
              // disabled={disabled.includes("3")}
              // disabled={true}
            >
              <DetailProductConfirm dataInputDetail={dataInputDetail} />
            </TabPane>
            <TabPane tab="こだわり条件設定" key="4" disabled={false}>
              <CommitmentConfirm dataInputCommitment={dataInputCommitment} />
            </TabPane>
            <TabPane
              tab="商品価格・送料設定"
              key="2"
              // disabled={disabled.includes("2")}
              // disabled={true}
            >
              <PriceConfirm dataInputPrice={dataInputPrice} />
            </TabPane>
          </Tabs>

          <Row className="!flex justify-center">
            <Space>
              <Button
                className="!bg-[#E4E8EB]"
                onClick={() => {
                  navigate(-1)
                }}
              >
                一覧ページに戻る
              </Button>

              <Button
                type="danger"
                onClick={updateProduct}
                loading={isloadingUpdate}
              >
                出品停止する
              </Button>
            </Space>
          </Row>
        </Spin>
      </Content>
    </div>
  )
}

export default SuperListedProductDetail
