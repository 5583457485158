import { Button, Col, Divider, Row, Space } from "antd"
import React from "react"
import ConfirmField from "../../../customComponents/confirmFields/ConfirmField"

export default function CommitmentConfirm({ dataInputCommitment }) {
  const processedData = { ...dataInputCommitment }

  return (
    <div className="pb-10">
      <ConfirmField label="産地" required>
        {processedData?.origin?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="販売時期" required>
        {processedData?.saleTime?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="業態" required>
        {processedData?.businessFormat?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="使用用途・調理方法" required>
        {processedData?.usageMethod?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="自慢ポイント" required>
        {processedData?.proudPoint?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="客層別" required>
        {processedData?.customerBase?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="人気条件" required>
        {processedData.popularity?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField label="温度帯" required>
        {processedData?.temperatureZone?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
    </div>
  )
}
