import { Button, Col, Divider, Image, Row, Space, Tag } from "antd"
import React from "react"
import ConfirmField from "../../../customComponents/confirmFields/ConfirmField"
import { temp_zone_data } from "../../../customComponents/detailProducts/assets/dataDetailProduct"
import IngredientConfirm from "../../../customComponents/detailProducts/confirms/IngredientConfirm"

export default function DetailProductConfirm({ dataInputDetail }) {
  console.log(dataInputDetail)
  const processedData = { ...dataInputDetail }
  return (
    <div className="pb-10">
      <ConfirmField label="アレルギー物質" required>
        {processedData?.tags?.map((item, index) => {
          return (
            <div key={index}>
              {item}
              <Divider type="vertical" />
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField
        label={
          <div style={{ whiteSpace: "pre-line" }}>
            {"アレルゲンコンタ\nミ注意喚起有無 "}
          </div>
        }
      >
        {/* <div>{processedData?.is_alert_allergen}</div> */}
        <div>{processedData?.alert_allergen}</div>
      </ConfirmField>
      <ConfirmField label="製造者情報">
        {processedData.manufacturers?.map((item, index) => {
          return (
            <div
              key={index}
              className={`${index > 0 && "mt-7"} flex flex-col gap-y-[5px]`}
            >
              <div>
                <span className="" style={{ wordBreak: "break-all" }}>
                  <span className="font-medium"> 担当工程:</span>
                  <span className="ml-3">
                    {item?.manufacturer_process_charge}
                  </span>
                </span>
              </div>
              <div style={{ wordBreak: "break-all" }}>
                <span className="font-medium">企業名:</span>
                <span className="ml-3">{item?.manufacturer_company_name}</span>
              </div>
              <div className="flex">
                <span className="font-medium">住所:</span>
                <span className="ml-3">{item?.manufacturer_post_code}</span>
                <span className="ml-1">{item?.manufacturer_prefecture}</span>
                <span className="ml-1">{item?.manufacturer_municipality}</span>
                <span className="ml-1">
                  {item?.manufacturer_address_detailed}
                </span>
              </div>
              <div className="flex">
                <span className="font-medium">TEL:</span>
                <span className="ml-3">{item?.manufacturer_tel}</span>
              </div>
            </div>
          )
        })}
      </ConfirmField>
      <ConfirmField
        label={
          <div style={{ whiteSpace: "pre-line" }}>
            {"最終加工者 \n（最終商品出荷元）"}
          </div>
        }
      >
        <div className="gap-y-[5px] flex flex-col">
          <div style={{ wordBreak: "break-all" }}>
            <span className="font-medium">担当工程:</span>
            <span className="ml-3">{processedData?.final_process_chare}</span>
          </div>
          <div style={{ wordBreak: "break-all" }}>
            <span className="font-medium">企業名:</span>
            <span className="ml-3">{processedData?.final_company_name}</span>
          </div>
          <div className="whitespace-nowrap">
            <span className="font-medium"> 住所:</span>
            <span className="ml-3 whitespace-nowrap">
              {processedData?.final_post_code}{" "}
            </span>
            <span className="ml-1">
              {processedData?.final_prefecture || ""}
            </span>
            <span className="ml-1">
              {processedData?.final_municipality || ""}
            </span>
            <span className="ml-1">{processedData?.final_address || ""}</span>
          </div>
          <div className="flex">
            <span className="font-medium">TEL:</span>
            <span className="ml-3">{processedData?.final_tel}</span>
          </div>
        </div>
      </ConfirmField>
      <ConfirmField
        label="原材料名"
        value={processedData?.raw_material_name}
        required
      />
      <ConfirmField label="添加物名" value={processedData?.additive_name} />
      <ConfirmField
        label="原産地"
        value={processedData?.place_origin}
        required
      />
      {/* <ConfirmField
        label="栄養成分表示"
        value={processedData?.NutritionFacts}
      /> */}
      <ConfirmField
        label="分析単位選択"
        value={processedData?.analytical_unit}
      />
      <IngredientConfirm processedData={processedData} />
      <ConfirmField label="賞味・消費区分（日付設定）" required>
        <div>{processedData?.selection_taste}</div>
        <div>{processedData?.date_setting}</div>
      </ConfirmField>
      <ConfirmField
        label="保存温度帯"
        value={
          processedData?.temp_zone
            ? temp_zone_data[processedData?.temp_zone]
            : ""
        }
        required
      />
      <ConfirmField label="類似品販売報告">
        <div>{processedData?.sales_report}</div>
      </ConfirmField>

      <div>
        <Row style={{ margin: "2rem" }}>
          <Col span={6}>
            <div className="flex">
              <Tag
                style={{
                  height: "fit-content",
                  backgroundColor: "#E4E8EB",
                }}
              >
                任意
              </Tag>
              <div className="flex flex-col">
                <div className="font-bold">商品一括表示画像登録</div>
                <div style={{ fontSize: 12 }}>(品質表示ラベル)</div>
              </div>
            </div>
          </Col>
          <Col span={16}>
            <div className="flex flex-wrap formImag gap-[10px] max-w-[850px]">
              {dataInputDetail?.images2?.map((item, index) => {
                if (item.type == "ACCURACY") {
                  return (
                    <Image
                      style={{
                        objectFit: "cover",
                        width: 140,
                        height: 140,
                      }}
                      key={index}
                      src={item?.image?.url}
                      alt="collectionTitle"
                    />
                  )
                }
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
