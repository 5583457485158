import React from "react"
import Topbar from "../../../components/organisms/topbar"
import SuperListedProductDetail from "../../../domain/superListedProductManagement/Detail"
import CustomLayout from "../../../Layouts/Layout"
import { getSearchParams } from "gatsby-query-params"
import "../styles.scss"

const index = () => {
  const query = getSearchParams()
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={
          <SuperListedProductDetail product_id={query?.product_id} />
        }
      />
    </div>
  )
}

export default index
